body > header {
    background-color: #bf945c;
    border-bottom-color: #bf945c;
}

.header_p {
    background-color: #bf945c;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #bf945c;
    border-color: #bf945c;
}

.pagination > li > a, .pagination > li > span {
    color: #bf945c;
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

.content {
    border-left: #bf945c;
}

.content #fiche-produit .wrap-description .prix {
    border-color: #bf945c;
}

#navigation .triangle {
    border-bottom-color: transparent !important;
    border-bottom-width: 25px;
    border-bottom-style: solid;
    border-right: 10px solid #FFFFFF;
    border-top-color: transparent !important;
    border-top-width: 25px;
    border-top-style: solid;
    content: "";
    display: block;
    left: -11px;
    position: absolute;
    z-index: 1;
}

.produit_etiquette:after {
    border-color: rgb(191, 148, 92) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);

}

.produit_etiquette {
    background: rgb(191, 148, 92);
}

.produit_etiquette2 {
    background: rgb(191, 148, 92);

}

.produit_etiquette2:after {
    border-color: rgba(0, 0, 0, 0) rgb(155, 118, 73) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);

}

.content #fiche-produit .remise-produit {
    background-color: #bf945c;
    color: white;
}

.diaporama .triangle {
    border-left-color: #fff;
}

.diaporama:hover .triangle {
    border-left-color: #fff;
}

.diaporama {
    clear: both;
    margin-top: 20px;
    position: relative;
}

#nav-principal {
    border-color: #BF945C;
    .navigation_link_p{
        color: #000;
    }
    .navigation_button_p{
        border-bottom-color: #BF945C;
        background-color: #fff;
        &.actif, &:hover, &.sousmenu_ouvert{
            background: #BF945C;
            .navigation_link_p{
                color:#fff;
            }
        }
        ul{
            border-color: #BF945C;
        }
    }
    .sous_navigation_link_p{
        color: #000;
    }
    .sous_navigation_button_p{
        border-bottom-color: #BF945C;
        background: #fff;
        &.actif, &:hover, &.sousmenu_ouvert{
            background: #BF945C;
            > .sous_navigation_link_p{
                color: #fff;
            }
        }
    }
}

nav.arianne ul {
}

.galerie {
    border-top-color: #bf945c;
    box-shadow: 0 1px 2px #bf945c;
}

.mask > .commentaire {
    border-bottom-color: rgba(255, 255, 255, 0.3);
}

.produits {
    border-top-color: #bf945c;
}

.produits .desc {
    border-bottom-color: #bf945c;
    color: #000;
}

.produits .remise {
    background-color: #bf945c;
    color: #fff;
}

.message_lo {
    border-left-color: #bf945c;
    box-shadow: 0 1px 2px #bf945c;
    background: #fff;
}

.content #fiche-produit .wrap-description .sociaux {
    border-color: #A37E4F;
}

body {
    background: none repeat scroll 0 0 #FFE9D3;
}

.content {
    background: none repeat scroll 0 0 #FFE9D3;
}

.ie-inf-9 .message_lo {
    border-top-color: #bbb;
    border-bottom-color: #bbb;
    border-right-color: #bbb;
}

footer {
    background: none repeat scroll 0 0 #bf945c;
    border-bottom: 1px solid #bf945c;
    color: #fff;
    .links_p a {
        color: #fff;
    }
}

#iview-preloader div {
    background: #666;
}

.iview-caption {
    color: #bf945c;
}

.iview-caption.caption2 {
    background: #00b4ff;
}

.iview-caption.blackcaption {
    background: #bf945c;
    border-radius: 50%;
    padding: 5px;
    height: 80px;
    width: 80px;
    font-size: 9px;
}

.ie7 .adr {
    background: black;
}

.blackcaption .caption-contain {
    margin-top: 30px;
    padding: 0px;
}

#iview-preloader {
    border-color: #666;
}

.adresse {
    background: rgba(191, 148, 52, 0.8);
}

.links_p a{
    color:#bf945c;
}

.links_p a.button, .links_p .button, .links_p button, .produits .addbasket, .links_p a.addmsglo, .addmsglo {
    background: none repeat scroll 0 0 #bf945c;
    color: #fff;
    border-color: #bf945c;
}

.links_p a.button:hover, .links_p .button:hover, .links_p button:hover, .produits .addbasket:hover, .links_p a.addmsglo:hover, .addmsglo:hover , .addbasketHover{
    background: none repeat scroll 0 0 #E8B470;
    color: #fff;
    border-color: #E8B470;
}

.iview-caption.caption4 {
    background: #fff;
    font-size: 16px;
    font-weight: bold;
}

.lien-mask {
    background: #000;
    background-color: #bf945c;
}

.adr {
    color: #FFFFFF;
}

.url {
    color: #fff;
}

.slogan, .mask > .commentaire a, .mask > h3, .mask > .h3, #footer a, .iview-caption a {
    color: #fff;
}

h2, .h2, .produits .nomprod {
    border-bottom-color: #bf945c;
    color: #bf945c;
    a, a:hover{
        color:inherit;
    }
}

.title_section a, nav.arianne ul li a, .fil-dariane a {
    color: #000;
}

.title_section a:hover, .produits .oldprix {
    color: #bf945c;
}

.lien {
    color: #000;
}

.produits select, .form input[type=text], .form textarea {
    border-color: #bf945c;
}

.message_lo:before {
    color: #eee;
}

/* Template Panier */

$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #bf945c;
    border-color: #bf945c;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #bf945c;
    color: #bf945c;

    a, span {
        color: #bf945c;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}